import Chart from 'chart.js/auto';
import regression from 'regression';

import idempotence from '../lib/idempotence';
import ready from '../ready';

const growthGraph = {
  init() {
    document.querySelectorAll('[data-growth-chart]').forEach((canvas) => {
      if (canvas && !idempotence.guard(canvas, 'growth')) {
        const data = JSON.parse(canvas.dataset.chartData || '{}');
        if (Object.keys(data).length === 0) {
          $.ajax({
            dataType: 'json',
            success: (response) => {
              canvas.classList.remove('d-none');
              this.draw(canvas, response.data);
            },
            type: 'GET',
            url: '/growth'
          });
        } else {
          this.draw(canvas, data);
        }
      }
    });
  },

  draw(canvas, data) {
    const extrapolationPeriods = JSON.parse(canvas.dataset.chartExtrapolate || '[]');
    const totals = JSON.parse(canvas.dataset.chartTotals || '[]');
    const colors = [
      '#6f40c8',
      '#7ad746',
      '#cb4bc0',
      '#61d588',
      '#56296f',
      '#d0d049',
      '#6e74cc',
      '#618939',
      '#cb4771',
      '#81d8cf',
      '#d44d33',
      '#84a4c9',
      '#bf853b',
      '#433851',
      '#c4d397',
      '#cb8bbf',
      '#3d4a2a',
      '#72372f',
      '#59897a',
      '#cb9b8d'
    ];
    const font = {
      color: '#212529',
      family: "'Lato', 'Source Sans Pro', sans-serif",
      size: 16
    };
    const datasets = [];
    if (extrapolationPeriods.length > 0 && totals.length > 0) {
      const numPeriodsToProject = Object.values(data.rows)[0].length + extrapolationPeriods.length;
      const trend = regression.logarithmic(totals.map((y, idx) => [idx + 1, y]), { order: 10 });
      const trendAdjustment = totals[totals.length - 1] - trend.predict(totals.length)[1];
      datasets.push(
        {
          backgroundColor: '#bb4455',
          borderColor: '#bb4455',
          borderJoinStyle: 'round',
          borderWidth: 3,
          data: Array(numPeriodsToProject).fill(0).map((_, idx) => Math.max(
            trend.predict(idx + 1)[1] + trendAdjustment,
            0
          )),
          fill: false,
          label: 'Trend',
          pointRadius: 3,
          stack: 'trend'
        }
      );
    }
    const keys = Object.keys(data.rows);
    keys.sort((a, b) => ((a > b || a === 'Goals') ? 1 : -1)).forEach((rowLabel, i) => {
      const isGoals = rowLabel === 'Goals';
      datasets.push({
        backgroundColor: colors[i],
        borderColor: colors[i],
        borderJoinStyle: 'round',
        borderWidth: 1.5,
        data: data.rows[rowLabel],
        fill: true,
        label: rowLabel,
        order: isGoals ? 1 : 2,
        pointBackgroundColor: colors[i],
        pointRadius: canvas.dataset.chartPointRadius || 0,
        stack: isGoals ? 'goals' : 'aggregate'
      });
    });
    new Chart(canvas, { // eslint-disable-line no-new
      data: {
        datasets,
        labels: data.headers.concat(extrapolationPeriods)
      },
      options: {
        plugins: {
          legend: {
            display: keys.length > 1,
            font
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            title: {
              font
            }
          },
          y: {
            stacked: true,
            title: {
              font
            }
          }
        }
      },
      type: 'line'
    });
  }
};

ready(() => {
  growthGraph.init();
});

export default growthGraph;
