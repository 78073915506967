import idempotence from '../lib/idempotence';
import ready from '../ready';

const dataTables = {
  init() {
    document.querySelectorAll('[data-js-datatable]').forEach((table) => {
      if (!idempotence.guard(table, 'datatable')) {
        $(table).DataTable();
      }
    });
  }
};

ready(() => {
  dataTables.init();
});

export default dataTables;
