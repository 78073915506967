/* Import TinyMCE */
import tinymce from 'tinymce';

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default';

/* Required TinyMCE components */
import 'tinymce/themes/silver';
import 'tinymce/models/dom';

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.css';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';

/* Import premium plugins */
/* NOTE: Download separately and add these to /src/plugins */
/* import './plugins/checklist/plugin'; */
/* import './plugins/powerpaste/plugin'; */
/* import './plugins/powerpaste/js/wordimport'; */

/* content UI CSS is required */
import contentUiSkinCss from 'tinymce/skins/ui/oxide/content.css?inline';

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
import contentCss from 'tinymce/skins/content/default/content.css?inline';

import idempotence from '../lib/idempotence';
import ready from '../ready';

const wysiwygEditor = {
  init() {
    document.querySelectorAll('[data-js-wysiwyg]').forEach((textarea) => {
      if (!idempotence.guard(textarea, 'tinymce')) {
        tinymce.init({
          content_css: false, // eslint-disable-line camelcase
          content_style: `${contentCss}\n${contentUiSkinCss}`, // eslint-disable-line camelcase
          menubar: false,
          plugins: 'advlist emoticons fullscreen image link lists',
          selector: `[data-js-wysiwyg="${textarea.dataset.jsWysiwyg}"]`,
          skin: false,
          toolbar: 'fontfamily fontsize | styles | bold italic underline | forecolor backcolor | removeformat | image link | bullist numlist | fullscreen | emoticons'
        });
      }
    });
  }
};

ready(() => {
  wysiwygEditor.init();
});

export default wysiwygEditor;
