import authenticityToken from '../authenticityToken';
import idempotence from '../lib/idempotence';
import ready from '../ready';

const toggler = {
  init() {
    document.querySelectorAll('[data-js-toggler]').forEach((element) => {
      if (!idempotence.guard(element, 'toggler')) {
        element.addEventListener('change', () => {
          const data = { value: element.checked };
          const token = authenticityToken();
          if (token) data.authenticity_token = token; // eslint-disable-line camelcase

          $.ajax({
            context: element,
            data,
            dataType: 'json',
            method: 'PUT',
            success: () => {
              const updated = document.createElement('span');
              updated.classList.add('ml-2', 'fade', 'show', 'text-green');
              updated.textContent = 'Updated!';
              const label = element.parentNode.getElementsByTagName('label')[0];
              if (label) {
                label.appendChild(updated);
              }
              setTimeout(() => {
                updated.classList.remove('show');
              }, 750);
            },
            url: element.getAttribute('data-js-toggler')
          });
        });
      }
    });
  }
};

ready(() => {
  toggler.init();
});

export default toggler;
