import Choices from 'choices.js/src/scripts/choices';

import idempotence from '../lib/idempotence';
import ready from '../ready';

const choices = {
  init() {
    document.querySelectorAll('[data-js-choices]').forEach((field) => {
      if (!idempotence.guard(field, 'choices-js')) {
        new Choices(field, { // eslint-disable-line no-new
          removeItemButton: true
        });
      }
    });
  },
};

ready(() => {
  choices.init();
});

export default choices;
