import loadGoogleMapsApi from 'load-google-maps-api';

import idempotence from '../lib/idempotence';
import ready from '../ready';

const forms = {
  init() {
    document.querySelectorAll('.city-input').forEach((input) => {
      if (!idempotence.guard(input, 'city-input')) {
        loadGoogleMapsApi({
          key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
          libraries: ['places'],
          v: 3
        }).then((mapsApi) => {
          new mapsApi.places.Autocomplete(input, { types: ['(cities)'] }); // eslint-disable-line no-new
        });
      }
    });
  }
};

ready(() => {
  forms.init();
});

export default forms;
