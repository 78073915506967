import ready from '../ready';

const tooltip = {
  init() {
    $('[data-toggle="tooltip"]').tooltip();
  }
};

ready(() => {
  tooltip.init();
});

export default tooltip;
