import chartjsGraph from './graphs/chartjsGraph';
import growthGraph from './graphs/growthGraph';
import mapGraph from './graphs/mapGraph';

import choices from './components/choices';
import wysiwygEditor from './components/wysiwygEditor';
import toggleTargetVisibility from './components/toggleTargetVisibility';
import toggler from './components/toggler';

import dataTables from './initializers/dataTables';
import forms from './initializers/forms';
import tooltip from './initializers/tooltip';

const ControlRoom = {
  choices,
  graphs: {
    chartjsGraph,
    growthGraph,
    mapGraph
  },
  dataTables,
  forms,
  wysiwygEditor,
  toggleTargetVisibility,
  toggler,
  tooltip
};

export default ControlRoom;
