import Chart from 'chart.js/auto';
import noUiSlider from 'nouislider';

import idempotence from '../lib/idempotence';
import ready from '../ready';

const chartjsGraph = {
  init() {
    document.querySelectorAll('[data-chartjs]').forEach((canvas) => {
      if (!idempotence.guard(canvas, 'chart-js')) {
        const chartType = canvas.dataset.charttype;
        const chartData = JSON.parse(canvas.dataset.chartdata);
        const chartOptions = JSON.parse(canvas.dataset.chartoptions);
        this.drawChart(canvas, chartType, chartData, chartOptions);
      }
    });
  },

  drawChart(canvas, type, data, options) {
    let chart;
    const ctx = canvas.getContext('2d');

    if (data.rangeSlider) {
      const slider = document.createElement('div');
      slider.classList.add('cjs-slider');
      let min = options.min || 0;
      const rangeMax = data.datasets[0].data.length - 1;
      let max = options.max || rangeMax;
      const display = document.createElement('div');
      display.classList.add('cjs-slider-display');
      chart = new Chart(ctx, { data: this.getData(data, min, max), options, type });

      noUiSlider.create(slider, {
        connect: true,
        range: { min: 0, max: rangeMax },
        start: [0, rangeMax],
        step: 1
      });

      this.setRangeSliderDisplay(display, chart);

      slider.noUiSlider.on('slide', (b) => {
        min = parseInt(b[0], 10);
        max = parseInt(b[1], 10) + 1;
      });

      slider.noUiSlider.on('change', () => {
        const updatedData = this.getData(data, min, max);
        chart.data.datasets = updatedData.datasets;
        chart.data.labels = updatedData.labels;
        chart.update();
        this.setRangeSliderDisplay(display, chart);
      });

      canvas.after(slider);
      slider.after(display);
    } else {
      chart = new Chart(ctx, { type, data, options });
    }
    this.initLinks(canvas, chart);
    return chart;
  },

  initLinks(canvas, chart) {
    canvas.addEventListener('click', () => {
      if (!chart || !chart.data.datasets) return;
      if (!chart._active || !chart._active[0]) return;
      const dataset = chart.data.datasets[chart._active[0].datasetIndex];
      if (!dataset || !dataset.links) return;
      const uri = dataset.links[chart._active[0].index];
      if (uri) {
        window.location = uri;
      }
    });
  },

  setRangeSliderDisplay(display, chart) {
    display.replaceChildren();
    const start = chart.data.labels[0];
    const end = chart.data.labels[chart.data.labels.length - 1];
    const daterangeLabel = document.createElement('div');
    daterangeLabel.classList.add('cjs-slider-display-label');
    daterangeLabel.innerHTML = `From <b>${start}</b> to <b>${end}</b>`;
    display.append(daterangeLabel);

    for (let i = 0; i < chart.data.datasets.length; i++) {
      const dataset = chart.data.datasets[i];
      if (dataset.summable) {
        const value = dataset.data.reduce((acc, val) => acc + val);
        const valueLabel = document.createElement('div');
        valueLabel.classList.add('cjs-slider-display-value');
        valueLabel.innerHTML = `<b>${value}</b> ${dataset.label}`;
        display.append(valueLabel);
      }
    }
  },

  getData(data, min, max) {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.labels = dataCopy.labels.slice(min, max + 1);

    for (let i = 0; i < dataCopy.datasets.length; i++) {
      const tempDataset = dataCopy.datasets[i];
      Object.keys(tempDataset).forEach((key) => {
        if (tempDataset[key] && tempDataset[key].constructor === Array) {
          dataCopy.datasets[i][key] = tempDataset[key].slice(min, max + 1);
        }
      });
    }
    return dataCopy;
  }
};

ready(() => {
  chartjsGraph.init();
});

export default chartjsGraph;
