import loadGoogleMapsApi from 'load-google-maps-api';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import idempotence from '../lib/idempotence';
import ready from '../ready';

const mapGraph = {
  init() {
    const mapElement = document.getElementById('map-canvas');
    if (mapElement === null) return;

    loadGoogleMapsApi({
      key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
      libraries: ['geometry'],
      v: 3
    }).then((mapsApi) => {
      if (!idempotence.guard(mapElement, 'map')) {
        if (typeof mapMarkerData !== 'undefined') {
          mapElement.innerHTML = '';
          this.draw(mapsApi, mapMarkerData, mapElement);
        } else {
          const self = this;
          $.ajax({
            dataType: 'json',
            success: (data) => {
              mapElement.innerHTML = '';
              self.draw(mapsApi, data.markers_data, mapElement);
            },
            type: 'GET',
            url: '/map'
          });
        }
      }
    });
  },

  draw(mapsApi, markersData, mapElement) {
    const map = new mapsApi.Map(mapElement, {
      fullscreenControl: true,
      mapTypeControl: false,
      rotateControl: false,
      scaleControl: true,
      streetViewControl: false,
      styles: [
        { featureType: 'administrative', elementType: 'geometry.stroke', stylers: [{ color: '#94a2af' }] },
        { featureType: 'administrative', elementType: 'labels.text.fill', stylers: [{ color: '#515151' }] },
        {
          featureType: 'administrative',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#666666' }, { weight: '0.01' }]
        },
        {
          featureType: 'administrative.province',
          elementType: 'geometry.stroke',
          stylers: [{ visibility: 'simplified' }]
        },
        { featureType: 'administrative.province', elementType: 'labels', stylers: [{ visibility: 'off' }] },
        { featureType: 'landscape', elementType: 'all', stylers: [{ visibility: 'on' }, { color: '#fffffa' }] },
        { featureType: 'landscape.natural', elementType: 'labels.text', stylers: [{ visibility: 'off' }] },
        { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }] },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [{ saturation: -100 }, { lightness: 45 }, { visibility: 'off' }]
        },
        { featureType: 'road.highway', elementType: 'all', stylers: [{ visibility: 'off' }] },
        { featureType: 'road.arterial', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
        { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }] },
        { featureType: 'water', elementType: 'all', stylers: [{ color: '#94a2af' }, { visibility: 'on' }] }
      ],
      zoomControl: true
    });

    const bounds = new mapsApi.LatLngBounds();

    const markers = markersData.map((location) => {
      const latLng = new mapsApi.LatLng(location.lat, location.lng);
      bounds.extend(latLng);
      const marker = new mapsApi.Marker({
        position: latLng
      });
      const infowindow = new mapsApi.InfoWindow({
        content: location.infowindow
      });
      marker.addListener('click', () => {
        infowindow.open(map, marker);
      });
      return marker;
    });

    new MarkerClusterer({ map, markers }); // eslint-disable-line no-new

    map.fitBounds(bounds);
  }
};

ready(() => {
  mapGraph.init();
});

export default mapGraph;
