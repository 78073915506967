import idempotence from '../lib/idempotence';
import ready from '../ready';

const toggleTargetVisibility = {
  init() {
    document.querySelectorAll('[data-js-toggle-target-visibility]').forEach((link) => {
      if (!idempotence.guard(link, 'toggle-visibility')) {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          JSON.parse(e.currentTarget.dataset.jsToggleTargetVisibility).forEach((target) => {
            document.querySelectorAll(`[data-js-target="${target}"]`).forEach((element) => {
              element.classList.toggle('d-none');
            });
          });
        });
      }
    });
  }
};

ready(() => {
  toggleTargetVisibility.init();
});

export default toggleTargetVisibility;
