import { update } from 'jdenticon';

import idempotence from './lib/idempotence';
import ready from './ready';

window.jdenticon_config = { // eslint-disable-line camelcase
  replaceMode: 'observe'
};

const jdenticon = () => {
  document.querySelectorAll('[data-jdenticon-value]').forEach((icon) => {
    if (!idempotence.guard(icon, 'jdenticonized')) {
      update(icon, icon.dataset.jdenticonValue);
    }
  });
};

window.jdenticon = jdenticon;

ready(() => {
  jdenticon();
});
