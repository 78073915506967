/* eslint no-console:0 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'bootstrap';
import '@fortawesome/fontawesome-pro/js/all';
import Rails from '@rails/ujs';
import 'admin-lte/dist/js/adminlte';
import 'vanilla-nested';

import '../src/bsCustomFileInput';
import '../src/jdenticon';
import '../src/sentry';
import ControlRoom from '../src';

window.ControlRoom = ControlRoom;
window.Rails = Rails;

if (!window._rails_loaded) {
  Rails.start();
}
